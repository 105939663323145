.App {
  align-items: center;
  background-color: #060606;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  height: 100vh;
  justify-content: center;
  overflow: hidden;
  position: relative;
  text-align: center;
}

.App > * {
  z-index: 10;
}
